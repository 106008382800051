<template>
  <app-fab
    ref="fab"
    v-model="fabOpen"
    only-mobile
    :icon="fabIcon"
  >
    <app-fab-bullet
      :label="$t(`${langPath}.share`)"
      :icon="shareIcon"
      data-test="bullet-share"
      @click="shareClick"
    />
    <app-fab-bullet
      :label="$t(`${langPath}.favorite`)"
      :icon="bookmarkIcon"
      data-test="bullet-favorite"
      @click="favoriteClick"
    />
    <app-fab-bullet
      :label="$t(`${langPath}.contact`)"
      :icon="chatIcon"
      data-test="bullet-invite"
      @click="goToSidebar"
    />
  </app-fab>
</template>

<script>
import { IcnShare, IcnStar, IcnChat, IcnAdd } from '@revelotech/everest'
import { CANDIDATES } from '@/store/namespaces'
import { createNamespacedHelpers } from 'vuex'
import eventBus from '@/support/event-bus'

const candidateHelper = createNamespacedHelpers(CANDIDATES)

export default {
  name: 'CandidateProfileFab',

  data () {
    return {
      bookmarkIcon: IcnStar,
      shareIcon: IcnShare,
      chatIcon: IcnChat,
      fabIcon: IcnAdd,
      fabOpen: false,
      langPath: __langpath
    }
  },

  methods: {
    ...candidateHelper.mapActions(['setFavoriteModal']),

    shareClick () {
      eventBus.emit('candidates/toggleShareProfileModal', true)

      this.fabOpen = false
    },

    favoriteClick () {
      this.setFavoriteModal(true)

      this.fabOpen = false
    },

    goToSidebar () {
      // TODO: replace with an event message that CandidateProfileSidebar listens to
      // and move scroll logic inside it.
      this.$refs.sidebar.scrollIntoView({ behavior: 'smooth' })

      this.fabOpen = false
    }
  }
}
</script>
