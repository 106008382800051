<template>
  <section ref="question" class="answers">
    <ev-circle-loader
      v-if="isFetchingQuestions"
      data-test="loader"
      variant="secondary"
    />

    <template v-else>
      <div
        v-for="question in questions"
        :key="question.id"
        class="answer"
      >
        <h4
          class="answer__title"
          data-test="profile-answer-title"
        >
          {{ $t(`${langPath}.question`) }}
          {{ question.text }}
        </h4>

        <span
          v-if="showStatus(question)"
          class="answer__status"
          data-test="profile-answer-status"
        >
          {{ $t(`${langPath}.answerStatus`) }}
        </span>

        <p
          class="answer__text"
          data-test="profile-answer-test"
        >
          <span v-if="question.answer" class="answer__date">
            {{ $t(`${langPath}.answered`) }}
            {{ question.answeredAt }}
          </span>
          {{ question.answer || $t(`${langPath}.waitingAnswer`) }}
        </p>
      </div>
    </template>
  </section>
</template>

<script>
import EvCircleLoader from '@revelotech/everestV2/EvCircleLoader'

import { createNamespacedHelpers } from 'vuex'
import { CANDIDATES } from '@/store/namespaces'

const { mapState } = createNamespacedHelpers(CANDIDATES)

export default {
  name: 'CandidateProfileAnswers',

  components: { EvCircleLoader },

  data () {
    return {
      langPath: __langpath
    }
  },

  computed: {
    ...mapState(['questions', 'isFetchingQuestions'])
  },

  async mounted () {
    if (this.$route.hash !== '#questions') return

    this.$refs.question.scrollIntoView({ behavior: 'smooth' })
  },

  methods: {
    showStatus (question) {
      return question.answer && question.selfQuestion
    }
  }
}
</script>

<style lang="scss" scoped>
.answers {
  @include margin(top, 8);
}

.answer {
  &:not(:first-of-type) {
    @include margin(top, 4);
  }

  &__title {
    @extend %subtitle1;

    word-break: break-all;
  }

  &__status {
    @extend %caption;

    color: var(--tx-blue);
  }

  &__text {
    @include margin(top, 4);

    @extend %body-text2;

    color: var(--tx-neutral-light-01);

    @include breakpoint(md) {
      border-left: 5px solid var(--b-neutral-light-04);
      padding-left: 4*$base;
    }
  }

  &__date {
    @extend %caption;

    color: var(--tx-neutral-light-01);
    display: block;
  }
}
</style>
