<template>
  <app-accordion :no-gutter="true" class="candidate-profile-sidebar-current-user-feedback">
    <template slot="defaultTrigger">
      <h2 class="candidate-profile-sidebar-current-user-feedback__title">
        <icn-down-arrow-red class="candidate-profile-sidebar-current-user-feedback__icon" />
        {{ $t(`${langPath}.title`) }}
      </h2>
    </template>

    <div
      class="candidate-profile-sidebar-current-user-feedback__score"
      data-test="score"
    >
      <h3 class="candidate-profile-sidebar-current-user-feedback__text">
        {{ $t(`${langPath}.score`) }}
      </h3>
      <p class="candidate-profile-sidebar-current-user-feedback__text">
        {{ currentUserFeedback.score }}
      </p>
    </div>

    <div>
      <div class="candidate-profile-sidebar-current-user-feedback__evaluation">
        <h2 class="candidate-profile-sidebar-current-user-feedback__evaluation-title">
          {{ $t(`${langPath}.profile`) }}
        </h2>
        <ul>
          <li
            v-for="evaluation in currentUserFeedback.profileEvaluations"
            :key="evaluation.id"
            class="candidate-profile-sidebar-current-user-feedback__evaluation-item"
            :data-test="`evaluation-${evaluation.id}`"
          >
            <p class="candidate-profile-sidebar-current-user-feedback__evaluation-item-text">
              {{ parseEvaluationName(evaluation.name) }}
            </p>
            <component
              :is="evaluationIconComponent(evaluation.score)"
              data-test="evaluation-icon"
            />
          </li>
        </ul>
      </div>

      <div class="candidate-profile-sidebar-current-user-feedback__evaluation">
        <h2 class="candidate-profile-sidebar-current-user-feedback__evaluation-title">
          {{ $t(`${langPath}.skills`) }}
        </h2>
        <ul>
          <li
            v-for="evaluation in currentUserFeedback.skillEvaluations"
            :key="evaluation.id"
            class="candidate-profile-sidebar-current-user-feedback__evaluation-item"
            :data-test="`evaluation-${evaluation.id}`"
          >
            <p class="evaluation-item__text">
              {{ evaluation.name }}
            </p>
            <component
              :is="evaluationIconComponent(evaluation.score)"
              data-test="evaluation-icon"
            />
          </li>
        </ul>
      </div>
    </div>

    <div
      class="candidate-profile-sidebar-current-user-feedback__description"
      data-test="description"
    >
      <h2
        class="candidate-profile-sidebar-current-user-feedback__description-title"
        data-test="evaluation-observation"
      >
        {{ $t(`${langPath}.observation`) }}
      </h2>
      <p class="candidate-profile-sidebar-current-user-feedback__description-content">
        {{ currentUserFeedback.text }}
      </p>
    </div>
  </app-accordion>
</template>

<script>
import {
  IcnThumbsUp,
  IcnThumbsDown,
  IcnNeutral,
  IcnDownArrowRed
} from '@revelotech/everest'

import { createNamespacedHelpers } from 'vuex'
import { FEEDBACKS } from '@/store/namespaces'

import { toCamelCase } from '@/helpers/case-style'

const feedbacksHelper = createNamespacedHelpers(FEEDBACKS)

export default {
  name: 'CandidateProfileSidebarCurrentUserFeedback',

  components: {
    IcnDownArrowRed
  },

  data () {
    return {
      langPath: __langpath,
      thumbsUp: IcnThumbsUp,
      thumbsDown: IcnThumbsDown,
      neutral: IcnNeutral,
      arrowIcnRed: IcnDownArrowRed
    }
  },

  computed: {
    ...feedbacksHelper.mapGetters(['currentUserFeedback'])
  },

  methods: {
    parseEvaluationName (evaluationName) {
      return this.$t(`${this.langPath}.${toCamelCase(evaluationName)}`)
    },

    evaluationIconComponent (score) {
      switch (score) {
        case 'up':
          return this.thumbsUp
        case 'down':
          return this.thumbsDown
        case 'neutral':
          return this.neutral
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-sidebar-current-user-feedback {
  border-bottom: 0;

  &__header,
  &__score {
    display: flex;
    justify-content: space-between;
    padding: 4*$base;
  }

  &__header {
    background-color: var(--bg-neutral-light-04);
  }

  &__title {
    @extend %subtitle1;

    color: var(--tx-red-light-01);
  }

  &__icon {
    margin-right: 3*$base;
  }

  &__edit {
    @extend %body-text2;

    &:hover {
      text-decoration: underline;
    }
  }

  &__score {
    border-bottom: 1px solid var(--b-neutral-light-04);
  }

  &__text {
    @extend %body-text2;
  }

  &__evaluation {
    &-title {
      background: var(--bg-neutral-light-04);
      border-bottom: 1px solid var(--b-neutral-light-04);
      color: var(--tx-neutral-light-01);
      margin-bottom: 0;
      padding: 4*$base;

      &:not(:first-child) {
        border-top: 1px solid var(--b-neutral-light-04);
      }
    }

    &-item {
      align-items: center;
      border-bottom: 1px solid var(--b-neutral-light-04);
      color: var(--tx-neutral-light-01);
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      padding: 2*$base 4*$base;

      &:last-child {
        border: 0;
      }
    }

    &-item-text {
      @extend %body-text2;
    }
  }

  &__description {
    background: var(--bg-neutral-light-04);
    border-top: 1px solid var(--b-neutral-light-04);
    padding: 6*$base 4*$base;

    &-title {
      @extend %subtitle1;

      color: var(--tx-red-light-01);
    }

    &-content {
      @extend %body-text2;

      @include margin(top, 4);
    }
  }
}
</style>
