<template>
  <div class="candidate-profile-sidebar-feedback-new">
    <ev-button
      v-if="!feedbackSubmitted"
      variant="tertiary"
      full-width
      data-test="open-modal-button"
      @click="openModal"
    >
      <span class="candidate-profile-sidebar-feedback-new__items">
        <icn-comment class="candidate-profile-sidebar-feedback-new__image" />
        {{ $t(`${langPath}.feedbackButton`) }}
      </span>
    </ev-button>

    <section>
      <app-modal v-if="showFeedbackModal" @close="closeModal" scroll>
        <template slot="header">
          <h2>{{ $t(`${langPath}.title`) }}</h2>
        </template>

        <candidate-profile-sidebar-feedback
          :candidate="candidate"
          data-test="feedback"
          @submit="registerFeedback"
        />
      </app-modal>
    </section>
  </div>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import { IcnComment } from '@revelotech/everest'

import CandidateProfileSidebarFeedback from './CandidateProfileSidebarFeedback'

export default {
  name: 'CandidateProfileSidebarFeedbackNew',

  components: {
    EvButton,
    CandidateProfileSidebarFeedback,
    IcnComment
  },

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath,
      showFeedbackModal: false,
      feedbackSubmitted: false
    }
  },

  methods: {
    openModal () {
      this.showFeedbackModal = true
    },
    closeModal () {
      this.showFeedbackModal = false
    },
    registerFeedback () {
      this.showFeedbackModal = false
      this.feedbackSubmitted = true
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-sidebar-feedback-new {
  @include margin(top, 3);

  text-align: center;

  &__image {
    @include margin(right, 1);
  }

  &__items {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
</style>
