<template>
  <section class="candidate-profile-questions">
    <ev-circle-loader
      v-if="isFetchingQuestions"
      data-test="loader"
      variant="secondary"
    />

    <h4
      v-else
      class="candidate-profile-questions__title"
      data-test="anonymous-questions-title"
    >
      {{ questionTitle }}
    </h4>

    <p class="candidate-profile-questions__description">
      {{ $t(`${langPath}.description`) }}
    </p>

    <ev-text-area
      v-model="candidateQuestion"
      :errors="errorMessages($v.candidateQuestion)"
      :placeholder="$t(`${langPath}.placeholder`)"
      class="candidate-profile-questions__textarea"
      data-test="anonymous-questions-input"
      name="candidateQuestion"
      @input="$v.$touch"
      @blur="$v.$touch"
    />

    <ev-button
      class="candidate-profile-questions__button"
      :disabled="$v.$invalid"
      variant="primary"
      data-test="anonymous-questions-button"
      @click="sendQuestion"
    >
      {{ $t(`${langPath}.sendButton`) }}
    </ev-button>
  </section>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import EvCircleLoader from '@revelotech/everestV2/EvCircleLoader'
import EvTextArea from '@revelotech/everestV2/EvTextArea'

import { createNamespacedHelpers } from 'vuex'
import { CANDIDATES } from '@/store/namespaces'

import { required, maxLength } from 'vuelidate/lib/validators'

import eventBus from '@/support/event-bus'

const candidatesHelper = createNamespacedHelpers(CANDIDATES)

const MAX_CANDIDATE_QUESTION_LENGTH = 420

export default {
  name: 'CandidateProfileQuestions',

  components: {
    EvButton,
    EvCircleLoader,
    EvTextArea
  },

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath,
      candidateQuestion: ''
    }
  },

  computed: {
    ...candidatesHelper.mapState(['questions', 'isFetchingQuestions']),

    questionTitle () {
      const i18nTag = this.questions?.length > 0 ? 'title' : 'withoutQuestion'

      return this.$t(`${this.langPath}.${i18nTag}`, { name: this.candidate.name })
    }
  },

  validations: {
    candidateQuestion: {
      required,
      maxLength: maxLength(MAX_CANDIDATE_QUESTION_LENGTH)
    }
  },

  methods: {
    ...candidatesHelper.mapActions(['createQuestion']),

    async sendQuestion () {
      this.$v.$touch()

      if (this.$v.$error) return

      await this.createQuestion({
        candidateId: this.candidate.id,
        question: this.candidateQuestion
      })
      eventBus.emit('alert-notification', {
        title: this.$t(`${this.langPath}.notificationCard.title`),
        message: this.$t(`${this.langPath}.notificationCard.message`),
        type: 'success'
      })

      this.candidateQuestion = ''
      this.$v.$reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-questions {
  @extend %candidate-profile-top-border;

  display: flex;
  flex-direction: column;

  &__title {
    @extend %subtitle1;
  }

  &__description {
    @include margin(top, 4);

    @extend %body-text2;
  }

  &__textarea {
    @extend %body-text2;
  }

  &__button {
    @include breakpoint(md) {
      align-self: flex-end;
    }
  }
}
</style>
