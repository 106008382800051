<template>
  <aside class="candidate-profile-sidebar">
    <div v-if="!currentUser.cannotInvite">
      <candidate-profile-sidebar-add-external-candidate
        v-if="skipInviteProcess"
        :global-candidate-id="globalCandidateId"
        :talent-position-id="talentPositionId"
        :position-title="positionTitle"
        :position-id="atsPositionId"
        data-test="add-external-candidate"
      />
      <candidate-profile-sidebar-go-to-invite
        v-else-if="candidate.invite"
        :candidate="candidate"
        data-test="go-to-invite"
      />
      <invites-new
        v-else
        data-test="new-invite"
        variant="only-white"
        :candidate="candidate"
        :fixed-position-id="fixedPositionId"
        :invite-form-data="additionalInviteData"
      />
    </div>

    <div v-if="loading" class="candidate-profile-sidebar__loading">
      <ev-circle-loader data-test="feedbacks-loading" variant="tertiary" />
    </div>

    <template v-else>
      <div class="candidate-profile-sidebar__feedback">
        <candidate-profile-sidebar-current-user-feedback
          v-if="currentUserFeedback"
          data-test="current-user-feedback"
        />
        <candidate-profile-sidebar-company-feedbacks
          v-if="feedbacks.length"
          data-test="company-feedbacks"
        />
      </div>

      <candidate-profile-sidebar-feedback-new
        v-if="isAvailableToFeedback"
        :candidate="candidate"
        data-test="new-feedback"
      />
    </template>
  </aside>
</template>

<script>
import CandidateProfileSidebarAddExternalCandidate
  from './CandidateProfileSidebar/CandidateProfileSidebarAddExternalCandidate.vue'
import CandidateProfileSidebarCompanyFeedbacks
  from './CandidateProfileSidebar/CandidateProfileSidebarCompanyFeedbacks'
import CandidateProfileSidebarCurrentUserFeedback
  from './CandidateProfileSidebar/CandidateProfileSidebarCurrentUserFeedback'
import CandidateProfileSidebarFeedbackNew
  from './CandidateProfileSidebar/CandidateProfileSidebarFeedbackNew'
import CandidateProfileSidebarGoToInvite
  from './CandidateProfileSidebar/CandidateProfileSidebarGoToInvite'
import InvitesNew from '@/pages/Invites/InvitesNew'
import EvCircleLoader from '@revelotech/everestV2/EvCircleLoader'
import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER, FEEDBACKS } from '@/store/namespaces'

const currentUserHelper = createNamespacedHelpers(CURRENT_USER)
const feedbackHelper = createNamespacedHelpers(FEEDBACKS)

export default {
  name: 'CandidateProfileSidebar',

  components: {
    EvCircleLoader,
    CandidateProfileSidebarGoToInvite,
    CandidateProfileSidebarFeedbackNew,
    CandidateProfileSidebarCurrentUserFeedback,
    CandidateProfileSidebarCompanyFeedbacks,
    InvitesNew,
    CandidateProfileSidebarAddExternalCandidate
  },

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...currentUserHelper.mapState(['currentUser']),
    ...feedbackHelper.mapState(['feedbacks']),
    ...feedbackHelper.mapGetters(['currentUserFeedback']),

    isAvailableToFeedback () {
      return Boolean(this.candidate?.invite?.interviewIdAvailableToFeedback)
    },
    fixedPositionId () {
      if (!this.$route.query?.fixedPositionId) return ''

      return this.$route.query.fixedPositionId
    },
    additionalInviteData () {
      return {
        talentPositionId: this.talentPositionId,
        globalCandidateId: this.globalCandidateId
      }
    },
    globalCandidateId () {
      return this.$route.query?.globalCandidateId
    },
    talentPositionId () {
      return this.$route.query?.talentPositionId
    },
    skipInviteProcess () {
      return this.$route.query.serviceLevel === 'sourcing_and_screening'
    },
    positionTitle () {
      return this.$route.query?.positionTitle
    },
    atsPositionId () {
      return this.$route.query?.atsPositionId
    }
  },

  async created () {
    if (!this.candidate.invite) return

    this.loading = true

    try {
      await this.getFeedbacks({ inviteId: this.candidate.invite.id })
    } finally {
      this.loading = false
    }
  },

  methods: {
    ...feedbackHelper.mapActions(['getFeedbacks'])
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-sidebar {
  z-index: 10;

  &__loading {
    padding: $base*4;
  }

  &__feedback {
    @include margin(top, 3);
  }

  &__invite {
    @include margin(top, 5);
  }
}
</style>
