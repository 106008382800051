<template>
  <div class="candidate-profile-sidebar-add-external-candidate">
    <h3 class="candidate-profile-sidebar-add-external-candidate__title">
      {{ $t(`${langPath}.title`) }}
    </h3>
    <p
      class="candidate-profile-sidebar-add-external-candidate__subtitle"
      data-test="position-title"
    >
      {{ positionTitle }}
    </p>
    <ev-button
      class="candidate-profile-sidebar-add-external-candidate__button"
      data-test="button"
      :loading="isSending"
      @click="handleSubmit"
    >
      {{ $t(`${langPath}.button`) }}
    </ev-button>
  </div>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import { TheNotificationCardList } from '@revelotech/everest'
import { createNamespacedHelpers } from 'vuex'
import { STAGES } from '@/store/namespaces'

const shortlistsHelpers = createNamespacedHelpers('shortlists')
const stagesHelper = createNamespacedHelpers(STAGES)

export default {
  name: 'CandidateProfileSidebarAddExternalCandidate',

  components: {
    EvButton
  },

  props: {
    globalCandidateId: {
      type: [String, Number],
      required: true
    },
    talentPositionId: {
      type: [String, Number],
      required: true
    },
    positionTitle: {
      type: String,
      default: ''
    },
    positionId: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath,
      isSending: false
    }
  },

  methods: {
    ...shortlistsHelpers.mapActions(['getShortlistCandidate']),
    ...stagesHelper.mapActions(['addExternalCandidate']),

    async handleSubmit () {
      this.isSending = true

      try {
        const talentCandidate = await this.getShortlistCandidate({
          positionId: this.positionId,
          globalCandidateId: this.globalCandidateId
        })
        await this.addExternalCandidate({
          positionId: this.positionId,
          candidate: {
            name: talentCandidate.name,
            email: talentCandidate.email,
            phone: talentCandidate.phone,
            linkedinUrl: talentCandidate.linkedin,
            source: 'shortlist'
          },
          shortlistAttributes: {
            globalCandidateId: this.globalCandidateId,
            talentPositionId: this.talentPositionId
          }
        })
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.addSuccess`),
          modifier: 'success'
        })
        this.$router.push({
          name: 'Shortlist',
          params: { id: this.positionId }
        })
      } catch {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.addError`),
          modifier: 'error'
        })
      } finally {
        this.isSending = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-sidebar-add-external-candidate {
  &__title {
    @extend %subtitle1;

    color: var(--tx-red-light-01);
    margin-bottom: 12px;
  }

  &__subtitle {
    @extend %subtitle2;

    color: var(--tx-gray-60);
    margin-bottom: 12px;
  }

  &__button {
    width: 100%;
  }
}
</style>
