<template>
  <app-modal
    key="confirm-modal"
    @close="$emit('close')"
    scroll
  >
    <template slot="header">
      <div class="candidate-profile-modal-confirm-invite__header">
        <ev-icon name="history" color="success" />
        <span
          class="candidate-profile-modal-confirm-invite__title"
          data-test="invite-modal-title"
        >
          {{ $t(`${langPath}.alreadyInvited`) }}
        </span>
      </div>
    </template>

    <div class="candidate-profile-modal-confirm-invite__body">
      <i18n
        :path="`${langPath}.activeProcess`"
        class="candidate-profile-modal-confirm-invite__text"
        tag="p"
      >
        <template #emphasis>
          <strong>
            {{ $t(`${langPath}.activeProcessEmphasis`) }}
          </strong>
        </template>
      </i18n>

      <i18n
        :path="`${langPath}.contact`"
        class="candidate-profile-modal-confirm-invite__text"
        tag="p"
      >
        <template #emphasis>
          <strong>
            {{ $t(`${langPath}.contactEmphasis`) }}
          </strong>
        </template>
      </i18n>

      <div class="candidate-profile-modal-confirm-invite__info-box">
        <p
          class="candidate-profile-modal-confirm-invite__text"
          data-test="confirm-modal-process"
        >
          <strong>
            {{ $t(`${langPath}.process`) }}:
          </strong>
          {{ candidate.invite.title }}
        </p>

        <p
          class="candidate-profile-modal-confirm-invite__text"
          data-test="confirm-modal-created-at"
        >
          <strong>
            {{ $t(`${langPath}.createdAt`) }}:
          </strong>
          {{ candidate.invite.createdAt }}
        </p>

        <p
          class="candidate-profile-modal-confirm-invite__text"
          data-test="confirm-modal-invited-by"
        >
          <strong>
            {{ $t(`${langPath}.invitedBy`) }}:
          </strong>
          {{ candidate.invite.employerName }}
        </p>
      </div>
    </div>

    <template slot="footer">
      <div class="candidate-profile-modal-confirm-invite__footer">
        <ev-button
          color="blue"
          @click="$emit('close')"
          variant="secondary"
          class="candidate-profile-modal-confirm-invite__button"
          data-test="confirm-modal-cancel"
        >
          {{ $t(`${langPath}.cancel`) }}
        </ev-button>

        <ev-button
          @click="$emit('confirm')"
          class="candidate-profile-modal-confirm-invite__button"
          data-test="confirm-modal-confirm"
        >
          {{ $t(`${langPath}.confirm`) }}
        </ev-button>
      </div>
    </template>
  </app-modal>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import EvIcon from '@revelotech/everestV2/EvIcon'

export default {
  name: 'CandidateProfileModalConfirmInvite',

  components: {
    EvButton,
    EvIcon
  },

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>
<style lang="scss" scoped>
.candidate-profile-modal-confirm-invite {
  &__header {
    @include margin(top, 4);

    align-items: center;
    display: flex;
  }

  &__body {
    @include margin(top, 4);

    @extend %body-text1;
  }

  &__footer {
    @include margin(top, 5);

    display: flex;
    justify-content: flex-end;
  }

  &__info-box {
    @include margin(top, 4);

    background-color: var(--bg-neutral-light-04);
    border: solid 1px var(--b-neutral-light-04);
    padding: $base*5 $base*3;
  }

  &__button {
    @include margin(left, 2);
  }

  &__title {
    @include margin(left, 2);

    color: var(--tx-success);
  }
}
</style>
