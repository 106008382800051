<template>
  <section class="candidates-profile-diversities">
    <h3
      class="candidates-profile-diversities__title"
      data-test="profile-diversities-title"
    >
      {{ $t(`${langPath}.title`) }}
    </h3>

    <div class="candidates-profile-diversities__container">
      <div
        class="candidates-profile-diversities__content
          candidates-profile-diversities__content--cid"
      >
        <h4
          class="candidates-profile-diversities__subtitle"
          data-test="disability-title"
        >
          {{ $t(`${langPath}.disability`) }}
        </h4>
        <p
          class="candidates-profile-diversities__info"
          data-test="disability-information"
        >
          {{ disabilityInformation }}
        </p>
      </div>

      <div
        v-if="candidate.specificNeeds"
        class="candidates-profile-diversities__content
          candidates-profile-diversities__content--specificNeeds"
      >
        <h4
          class="candidates-profile-diversities__subtitle"
          data-test="disability-specific-needs"
        >
          {{ $t(`${langPath}.specificNeeds`) }}
        </h4>
        <p
          class="candidates-profile-diversities__info"
          data-test="disability-specific-needs"
        >
          {{ candidate.specificNeeds }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CandidateProfileDiversities',

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath
    }
  },

  computed: {
    disabilityName () {
      return this.$t(`${this.langPath}.${this.candidate.disabilityName}`)
    },

    disabilityInformation () {
      const cid = this.candidate.disabilityCid ? `(CID ${this.candidate.disabilityCid})` : ''

      return `${this.disabilityName} ${cid}`
    }
  }
}
</script>

<style lang="scss" scoped>
.candidates-profile-diversities {
  @extend %candidate-profile-top-border;

  &__title {
    @extend %subtitle1;
  }

  &__container {
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  &__content {
    flex: 0 100%;
    margin-top: $base*5;

    @include breakpoint(md) {
      flex: 0 calc(100% / 3);
    }

    &--cid {
      flex: 0 30%;
    }

    &--specificNeeds {
      flex: 0 70%;
    }
  }

  &__subtitle {
    @extend %caption;

    color: var(--tx-neutral-light-01);
  }

  &__info {
    @extend %body-text2;

    &-text {
      text-align: left;
    }
  }
}
</style>
