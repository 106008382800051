<template>
  <div>
    <section class="candidate-profile-sidebar-go-to-invite">
      <div class="candidate-profile-sidebar-go-to-invite__body">
        <span
          class="candidate-profile-sidebar-go-to-invite__title"
          :class="completedProcessClass"
          data-test="invite-status"
        >
          {{ inviteStatusLabel }}
        </span>

        <a
          :href="inviteLink"
          class="candidate-profile-sidebar-go-to-invite__link"
          data-test="invite-link"
        >
          {{ candidate.invite.title }}
        </a>

        <a
          @click="openModal"
          class="candidate-profile-sidebar-go-to-invite__open-new-invite-link"
          data-test="open-new-invite"
        >
          {{ $t(`${langPath}.sendNewInvite`) }}
        </a>
      </div>
    </section>

    <section
      v-if="showLinkToScheduleInterview"
      class="candidate-profile-sidebar-schedule-interview"
      data-test="schedule-interview"
    >
      <div
        class="candidate-profile-sidebar-schedule-interview__description"
        data-test="schedule-interview-description"
      >
        {{ $t(`${langPath}.interviewDescription`, { candidateName: candidate.name }) }}
      </div>
      <ev-button
        data-ga-id="clicked_on_schedule_interview_button"
        @click="redirectToInterviewPage"
        data-test="schedule-interview-button"
      >
        {{ $t(`${langPath}.scheduleInterview`) }}
      </ev-button>
    </section>

    <candidate-profile-modal-confirm-invite
      v-if="showConfirmModal"
      :candidate="candidate"
      data-test="confirm-modal"
      @close="closeModal"
      @confirm="confirmModal"
    />

    <app-modal
      v-if="showInviteModal"
      data-test="invite-modal"
      scroll
      @close="closeModal"
    >
      <template slot="header">
        <h3 data-test="invite-modal-title">
          {{ $t(`${langPath}.inviteModalTitle`) }}
        </h3>

        <small
          v-if="candidate.invite.active"
          class="candidate-profile-sidebar-go-to-invite__warning-subtitle"
          data-test="invite-modal-warning"
        >
          {{ $t(`${langPath}.warningTitle`) }}
        </small>
      </template>

      <div
        key="invite-modal-body"
        class="candidate-profile-sidebar-go-to-invite__modal-body"
        scroll
      >
        <invites-new :show-title="false" variant="inverted" :candidate="candidate" />
      </div>
    </app-modal>
  </div>
</template>

<script>
import InvitesNew from '@/pages/Invites/InvitesNew'
import EvButton from '@revelotech/everestV2/EvButton'
import { companiesUrl } from '@/helpers/constants/external-urls'
import CandidateProfileModalConfirmInvite
  from '@/components/Candidates/CandidateProfileModal/CandidateProfileModalConfirmInvite'

export default {
  name: 'CandidateProfileSidebarGoToInvite',

  components: {
    InvitesNew,
    EvButton,
    CandidateProfileModalConfirmInvite
  },

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath,
      showInviteModal: false,
      showConfirmModal: false
    }
  },

  computed: {
    hasActiveInvite () {
      return this.candidate.invite.active
    },
    inviteStatusLabel () {
      return this.hasActiveInvite
        ? this.$t(`${this.langPath}.pending`)
        : this.$t(`${this.langPath}.completed`)
    },
    showLinkToScheduleInterview () {
      return this.hasActiveInvite && this.candidate.invite.awaitingCompanySchedule
    },
    inviteLink () {
      return `${companiesUrl}${this.candidate.invite.link}`
    },
    completedProcessClass () {
      return this.hasActiveInvite
        ? ''
        : 'candidate-profile-sidebar-go-to-invite__title--completed-process'
    }
  },

  methods: {
    openModal () {
      this.candidate.invite.active
        ? this.showConfirmModal = true
        : this.showInviteModal = true
    },
    redirectToInterviewPage () {
      this.$router.push({
        name: 'InvitesInterviewsNew',
        params: { inviteId: this.candidate.invite.id }
      })
    },
    closeModal () {
      this.showConfirmModal = false
      this.showInviteModal = false
    },
    confirmModal () {
      this.showConfirmModal = false
      this.showInviteModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-sidebar-go-to-invite {
  align-items: flex-start;
  display: flex;
  padding: 3*$base;

  &__body {
    @include margin(left, 3);

    display: flex;
    flex-direction: column;
  }

  &__title {
    color: var(--tx-neutral-light-01);
  }

  &__link {
    @extend %body-text1;
    @extend %bold;

    margin-bottom: 4*$base;
    margin-top: $base;
  }

  &__warning-subtitle {
    @include margin(top, 3);

    @extend %body-text1;

    color: var(--tx-warning);
    display: block;
  }

  &__modal-body {
    @include margin(top, 3);
  }

  a {
    color: var(--tx-blue);
    cursor: pointer;
    text-decoration: underline;
  }
}

.candidate-profile-sidebar-schedule-interview {
  display: inline-block;
  padding: 5*$base;

  &__description {
    margin-bottom: 2*$base;
  }
}
</style>
