<template>
  <app-modal
    v-if="favoriteModal"
    class="candidates-favorite-modal"
    scroll
    @close="closeModal"
  >
    <template #header>
      {{ $t(`${langPath}.title`) }}
    </template>
    <p
      class="candidates-favorite-modal__subtitle"
      data-test="candidates-favorite-modal-subtitle"
    >
      {{ $t(`${langPath}.subtitle`) }}
    </p>
    <section v-if="showForm">
      <app-text-input
        v-model="newWishList"
        data-test="wish-list-input"
        name="newFavorite"
      />
      <section class="candidates-favorite-modal__wishlist-buttons">
        <ev-button
          @click="closeForm"
          variant="tertiary"
          data-test="favorite-modal-button-cancel"
        >
          {{ $t(`${langPath}.cancel`) }}
        </ev-button>
        <ev-button
          @click="createWishListClick"
          :disabled="isCreatingWishList"
          data-test="create-wishlist"
        >
          {{ $t(`${langPath}.create`) }}
        </ev-button>
      </section>
    </section>
    <section v-else>
      <section class="candidates-favorite-modal__wishlist-buttons">
        <ev-button
          @click="openForm"
          class="candidates-favorite-modal__button"
          data-test="enable-create-new-wish-list"
        >
          {{ $t(`${langPath}.createNewWishList`) }}
        </ev-button>
      </section>
    </section>

    <ul class="candidates-favorite-modal__list">
      <li
        v-for="list in decoratedWishList"
        :key="list.id"
        :data-test="`toggle-favorite-${list.id}`"
        class="candidates-favorite-modal__wishlist"
        @click="toggleFavorite(list)"
      >
        <figure>
          <ev-icon
            name="star"
            color="blue-dark"
            v-if="list.favorite"
            class="candidates-favorite-modal__star"
          />
          <ev-icon
            name="star"
            color="gray40"
            v-else
            class="candidates-favorite-modal__star"
          />
        </figure>
        <span class="candidates-favorite-modal__wishlist-label">
          {{ list.name }}
        </span>
      </li>
    </ul>
    <template #footer>
      <section class="candidates-favorite-modal__footer">
        <ev-button
          color="blue"
          @click="closeModal"
          variant="secondary"
          data-test="close-modal-button"
        >
          {{ $t(`${langPath}.close`) }}
        </ev-button>
      </section>
    </template>
  </app-modal>
</template>

<script>
import CandidateProfileFavoriteButton from './CandidateProfileFavoriteButton'
import EvButton from '@revelotech/everestV2/EvButton'
import EvIcon from '@revelotech/everestV2/EvIcon'

import { CANDIDATES } from '@/store/namespaces'
import { createNamespacedHelpers } from 'vuex'

const candidateHelper = createNamespacedHelpers(CANDIDATES)

export default {
  name: 'CandidatesProfileFavoriteModal',

  components: {
    EvButton,
    EvIcon
  },

  extends: CandidateProfileFavoriteButton,

  data () {
    return {
      langPath: __langpath
    }
  },

  computed: {
    ...candidateHelper.mapState(['favoriteModal'])
  },

  methods: {
    ...candidateHelper.mapActions(['setFavoriteModal']),

    closeModal () {
      this.setFavoriteModal(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.candidates-favorite-modal {
  &__subtitle {
    @include margin(top, 4);

    @extend %body-text2;
  }

  &__wishlist-buttons {
    @include margin(top, 2);

    display: flex;
    justify-content: flex-end;
  }

  &__list {
    @include margin(top, 4);
  }

  &__wishlist {
    align-items: center;
    border-top: solid 1px var(--b-neutral-light-02);
    display: flex;
    padding: 3*$base 0;

    &:last-child {
      border-bottom: solid 1px var(--b-neutral-light-02);
    }
  }

  &__wishlist-label {
    @include margin(left, 2);

    @extend %body-text2;
  }

  &__star {
    height: 20px;
    width: 20px;
  }

  &__footer {
    @include margin(top, 4);

    display: flex;
    justify-content: flex-end;
  }
}
</style>
