<template>
  <app-modal
    v-if="showModal"
    class="candidate-profile-share-modal"
    data-test="modal"
    scroll
    @close="closeModal"
  >
    <template slot="header">
      <h2>{{ $t(`${langPath}.modalTitle`) }}</h2>
    </template>

    <div class="candidate-profile-share-modal__body">
      <app-text-area
        v-model="message"
        v-validate="'required'"
        :data-vv-as="$t(`${langPath}.messageLabel`)"
        :label="$t(`${langPath}.messageLabel`)"
        :placeholder="$t(`${langPath}.messagePlaceholder`)"
        data-test="input-message"
        name="message"
      />

      <app-autocomplete
        v-model="emails"
        v-validate="emailValidationRule"
        :items="employersList"
        :criteria="autoCompleteCriteria"
        :placeholder="$t(`${langPath}.emailPlaceholder`)"
        data-test="input-emails"
        direction="up"
        name="emails"
      />
    </div>

    <template slot="footer">
      <div class="candidate-profile-share-modal__footer">
        <ev-button
          data-test="submit"
          size="large"
          variant="primary"
          @click="sendCreateSharedCandidate"
        >
          {{ $t(`${langPath}.modalConfirm`) }}
        </ev-button>
      </div>
    </template>
  </app-modal>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import { createNamespacedHelpers } from 'vuex'
import {
  SHARED_CANDIDATES, EMPLOYERS, CURRENT_USER, NOTIFICATION_CARDS
} from '@/store/namespaces'
import eventBus from '@/support/event-bus'
import colorize from '@/helpers/colorize'

const sharedCandidateHelper = createNamespacedHelpers(SHARED_CANDIDATES)
const employersHelper = createNamespacedHelpers(EMPLOYERS)
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)
const notificationHelper = createNamespacedHelpers(NOTIFICATION_CARDS)

export default {
  name: 'CandidatesProfileShareModal',

  components: {
    EvButton
  },

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath,
      showModal: false,
      message: '',
      emails: ''
    }
  },

  computed: {
    ...currentUserHelper.mapState(['currentUser']),
    ...employersHelper.mapGetters(['employersWithoutSelf']),

    employersList () {
      return colorize(this.employersWithoutSelf).map((employer) => ({
        label: employer.email,
        value: employer.email,
        avatar: employer.name,
        avatarColor: employer.color
      }))
    },

    emailValidationRule () {
      return {
        required: true,
        emails_of_domain: this.currentUser.company.emailDomain
      }
    }
  },

  created () {
    eventBus.on('candidates/toggleShareProfileModal', (visibility) => {
      this.showModal = visibility
    })

    this.getEmployers()
  },

  methods: {
    ...sharedCandidateHelper.mapActions(['createSharedCandidate']),
    ...employersHelper.mapActions(['getEmployers']),
    ...notificationHelper.mapActions(['createNotificationCard']),

    closeModal () {
      eventBus.emit('candidates/toggleShareProfileModal', false)

      this.message = ''
      this.emails = ''
    },

    async sendCreateSharedCandidate () {
      const isValid = await this.$validator.validate()
      if (!isValid) return

      try {
        await this.createSharedCandidate({
          candidateId: this.candidate.id,
          message: this.message,
          emails: this.emails
        })

        this.createNotificationCard({
          title: this.$t(`${this.langPath}.successTitle`),
          message: this.$t(`${this.langPath}.successMessage`),
          modifier: 'success'
        })
      } catch {
        this.createNotificationCard({
          title: this.$t(`${this.langPath}.errorTitle`),
          message: this.$t(`${this.langPath}.errorMessage`),
          modifier: 'error'
        })
      } finally {
        this.closeModal()
      }
    },
    autoCompleteCriteria (search) {
      return search.split(',').pop().trim()
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-share-modal {
  &__body {
    @include margin(top, 3);
    @include margin(bottom, 3);
  }

  &__footer {
    text-align: right;
  }
}
</style>
