<template>
  <article class="candidate-profile">
    <app-loader v-if="isLoading" />

    <app-grid v-else>
      <app-grid-col :md="8">
        <app-container class="candidate-profile__container">
          <section class="candidate-profile__content">
            <article v-if="candidate.incompleteProfile">
              <candidate-profile-incomplete :candidate="candidate" />
              <candidate-profile-social :candidate="candidate" />
              <candidate-profile-cv :candidate="candidate" />
            </article>

            <article v-else>
              <candidate-profile-header :candidate="candidate" />
              <candidate-profile-diversities
                v-if="showDiversities"
                :candidate="candidate"
              />
              <candidate-profile-about
                :candidate="candidate"
                :highlighted-skill-ids="highlightedSkillIds"
              />
              <candidate-profile-social :candidate="candidate" />
              <candidate-profile-jobs
                :candidate="candidate"
                :highlighted-skill-ids="highlightedSkillIds"
              />
              <candidate-profile-educations :candidate="candidate" />
              <candidate-profile-endorsements :candidate="candidate" />
              <candidate-profile-questions
                :candidate="candidate"
              />
              <candidate-profile-answers />
              <candidate-profile-video :candidate="candidate" />
              <candidate-profile-fab
                v-if="showProfileFab"
              />
              <candidate-profile-favorite-modal :candidate="candidate" />
              <candidate-profile-share-modal :candidate="candidate" />
            </article>
          </section>
        </app-container>
      </app-grid-col>
      <app-grid-col :md="4">
        <div
          data-test="candidate-profile-sidebar-container"
          class="candidate-profile__container
            candidate-profile__auto-height candidate-profile__less-padding"
          :class="sidebarContainerClass"
        >
          <candidate-profile-sidebar :candidate="candidate" />
        </div>
      </app-grid-col>
    </app-grid>
  </article>
</template>

<script>
import CandidateProfileHeader from '@/components/Candidates/CandidateProfileHeader'
import CandidateProfileDiversities from '@/components/Candidates/CandidateProfileDiversities'
import CandidateProfileAbout from '@/components/Candidates/CandidateProfileAbout'
import CandidateProfileSocial from '@/components/Candidates/CandidateProfileSocial'
import CandidateProfileJobs from '@/components/Candidates/CandidateProfileJobs'
import CandidateProfileVideo from '@/components/Candidates/CandidateProfileVideo'
import CandidateProfileEducations from '@/components/Candidates/CandidateProfileEducations'
import CandidateProfileEndorsements from '@/components/Candidates/CandidateProfileEndorsements'
import CandidateProfileQuestions from '@/components/Candidates/CandidateProfileQuestions'
import CandidateProfileAnswers from '@/components/Candidates/CandidateProfileAnswers'
import CandidateProfileSidebar from '@/components/Candidates/CandidateProfileSidebar'
import CandidateProfileIncomplete from '@/components/Candidates/CandidateProfileIncomplete'
import CandidateProfileFab from '@/components/Candidates/CandidateProfileFab'
import CandidateProfileFavoriteModal from '@/components/Candidates/CandidateProfileFavoriteModal'
import CandidateProfileCv from '@/components/Candidates/CandidateProfileCv'
import CandidateProfileShareModal from '@/components/Candidates/CandidateProfileShareModal'

import gtmEvents from '@/helpers/constants/gtm-events'
import { createNamespacedHelpers } from 'vuex'
import { CANDIDATES, CURRENT_USER, SKILLS, COMPANY } from '@/store/namespaces'

import authRepository from '@/repository/companies-app/company'

const currentUserHelper = createNamespacedHelpers(CURRENT_USER)
const candidatesHelper = createNamespacedHelpers(CANDIDATES)
const companyHelper = createNamespacedHelpers(COMPANY)
const skillsHelper = createNamespacedHelpers(SKILLS)

export default {
  name: 'CandidateProfile',

  components: {
    CandidateProfileAbout,
    CandidateProfileSocial,
    CandidateProfileJobs,
    CandidateProfileDiversities,
    CandidateProfileEducations,
    CandidateProfileEndorsements,
    CandidateProfileQuestions,
    CandidateProfileAnswers,
    CandidateProfileSidebar,
    CandidateProfileVideo,
    CandidateProfileIncomplete,
    CandidateProfileCv,
    CandidateProfileFab,
    CandidateProfileFavoriteModal,
    CandidateProfileHeader,
    CandidateProfileShareModal
  },

  async beforeRouteEnter (_to, from, next) {
    try {
      const trial = await authRepository.getTrial()

      if (trial.onTrial === false) return next()

      const { completeProfileViews, completeProfileViewsLimit } = trial

      const cantAccess = completeProfileViews >= completeProfileViewsLimit

      if (cantAccess) {
        await this.$gtmTrackEvent(gtmEvents.companyTrialEndedByProfile)

        const redirectToPlans = (vm) => {
          if (vm.isLite) {
            vm.$router.push({ name: 'PlansLite', query: { restricted: true } })
          } else {
            vm.$router.push({ name: 'Plans' })
          }
        }

        return next(redirectToPlans)
      } else {
        return next()
      }
    } catch (error) {
      return next()
    }
  },

  data () {
    return {
      langPath: __langpath,
      isLoading: true
    }
  },

  computed: {
    ...currentUserHelper.mapState(['currentUser']),
    ...candidatesHelper.mapGetters(['getCandidateById']),

    highlightedSkillIds () {
      const skillIds = this.$route.query['skills[]']

      return typeof skillIds === 'string' ? [skillIds] : skillIds
    },

    isLite () {
      return this.currentUser?.company?.planChoice === 'plan_lite'
    },

    showDiversities () {
      return Boolean(this.currentUser?.searchForDisability && this.candidate.disabilityName)
    },

    isTrialVisualization () {
      return this.currentUser?.company?.trial && !this.candidate.canBeInvited
    },

    sidebarContainerClass () {
      return { 'candidate-profile__sidebar-container--sticky': !this.candidate.invite }
    },

    showProfileFab () {
      return this.currentUser && !this.isTrialVisualization
    },

    showHighlightedSkills () {
      return 'skills[]' in this.$route.query
    },

    candidate () {
      return this.getCandidateById(this.$route.params.id)
    }
  },

  async created () {
    this.getSkillsFromParams()

    await this.getCandidate({ candidateId: this.$route.params.id })
    this.isLoading = false

    document.title = `${this.candidate.name} | Revelo`

    this.getQuestions(this.candidate.id)
    this.getEvaluations(this.candidate.id)

    this.$gtmTrackEvent(gtmEvents.companyProfileView)
  },

  methods: {
    ...skillsHelper.mapActions(['getSkillsByIds']),
    ...companyHelper.mapActions(['getTrial']),
    ...candidatesHelper.mapActions(['getCandidate', 'getQuestions', 'getEvaluations']),

    getSkillsFromParams () {
      if (this.showHighlightedSkills) {
        return this.getSkillsByIds(this.highlightedSkillIds)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile {
  background: linear-gradient(to bottom, var(--bg-gray100) 248px, var(--bg-blue-light-03) 248px);
  margin-top: $base * 5;
  padding-bottom: $base * 6;
  padding-top: $base * 24;

  @include breakpoint(md) {
    padding-bottom: $base * 12;
    padding-top: $base*24;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
  }

  &__auto-height {
    height: auto;
  }

  &__sidebar-container--sticky {
    position: sticky;
    top: calc(#{$app-header-height} + 6 * #{$base});
  }

  &__less-padding {
    padding: 6*$base 4*$base 0;

    @include breakpoint(md) {
      padding: 6*$base 3*$base 0;
    }
  }

  &__container {
    background-color: var(--bg-neutral-blank);
    border: 1px solid var(--bg-neutral-light-04);
    box-shadow: $shadow-8;
    height: auto;
    padding: $base * 4;
    padding-bottom: $base * 6;
    width: 100%;

    @include breakpoint(md) {
      padding: $base * 6;
      padding-bottom: $base * 12;
    }

    @media print {
      border: 0;
    }
  }
}
</style>
