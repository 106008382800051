<template>
  <app-accordion :no-gutter="true" class="candidate-profile-sidebar-company-feedbacks">
    <template slot="defaultTrigger">
      <h2
        class="candidate-profile-sidebar-company-feedbacks__title"
        data-test="title"
      >
        <icn-down-arrow-red class="candidate-profile-sidebar-company-feedbacks__icon" />
        {{ $t(`${langPath}.title`) }}
      </h2>
    </template>

    <div
      class="candidate-profile-sidebar-company-feedbacks__score"
      data-test="score"
    >
      <h3 class="candidate-profile-sidebar-company-feedbacks__text">
        {{ $t(`${langPath}.score`) }}
      </h3>
      <p class="candidate-profile-sidebar-company-feedbacks__text">
        {{ averageScore }}
      </p>
    </div>

    <div>
      <div class="evaluation-group">
        <h2
          class="evaluation-group__title"
          data-test="evaluation-group-title"
        >
          {{ $t(`${langPath}.profile`) }}
        </h2>

        <app-accordion
          v-for="(profileEvaluations, evaluationName) in profileEvaluationsGroupedByName"
          :key="evaluationName"
          :inside-accordion="true"
          :no-gutter="true"
          data-test="profile-evaluation"
          variant="white"
        >
          <template slot="defaultTrigger">
            <h3
              class="evaluation-group__subgroup"
              data-test="evaluation-group-profilekey"
            >
              <icn-down-arrow class="evaluation-group__arrow" />
              {{ parseEvaluationName(evaluationName) }}
            </h3>
          </template>
          <ul>
            <li
              v-for="evaluation in profileEvaluations"
              :key="evaluation.id"
              class="evaluation-accordion__item"
              data-test="evaluation"
            >
              <p class="evaluation-accordion__text">
                {{ evaluation.fromUser.name }}
                <span
                  v-if="isCurrentUser(evaluation.fromUser.id)"
                  data-test="belongs-to-current-user"
                >
                  {{ $t(`${langPath}.currentUserEvaluation`) }}
                </span>
              </p>
              <component
                :is="scoreIcon(evaluation.score)"
                class="evaluation-accordion__icon"
                data-test="evaluation-icon"
              />
            </li>
          </ul>
        </app-accordion>
      </div>

      <div class="evaluation-group">
        <h2
          class="evaluation-group__title"
          data-test="evaluation-group-title"
        >
          {{ $t(`${langPath}.skills`) }}
        </h2>

        <app-accordion
          v-for="(skillEvaluations, evaluationName) in skillEvaluationsGroupedByName"
          :key="evaluationName"
          :inside-accordion="true"
          :no-gutter="true"
          data-test="skill-evaluation"
          variant="white"
        >
          <template slot="defaultTrigger">
            <h3 class="evaluation-group__subgroup">
              <icn-down-arrow class="evaluation-group__arrow" />
              {{ evaluationName }}
            </h3>
          </template>
          <ul>
            <li
              v-for="evaluation in skillEvaluations"
              :key="evaluation.id"
              class="evaluation-accordion__item"
              data-test="evaluation"
            >
              <p class="evaluation-accordion__text">
                {{ evaluation.fromUser.name }}
                <span
                  v-if="isCurrentUser(evaluation.fromUser.id)"
                  data-test="belongs-to-current-user"
                >
                  {{ $t(`${langPath}.currentUserEvaluation`) }}
                </span>
              </p>
              <component
                :is="scoreIcon(evaluation.score)"
                class="evaluation-accordion__icon"
              />
            </li>
          </ul>
        </app-accordion>
      </div>
    </div>
  </app-accordion>
</template>

<script>
import {
  IcnThumbsUp,
  IcnThumbsDown,
  IcnNeutral,
  IcnDownArrow,
  IcnDownArrowRed
} from '@revelotech/everest'

import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER, FEEDBACKS } from '@/store/namespaces'

import { toCamelCase } from '@/helpers/case-style'

const currentUserHelper = createNamespacedHelpers(CURRENT_USER)
const feedbacksHelper = createNamespacedHelpers(FEEDBACKS)

export default {
  name: 'CandidateProfileSidebarCompanyFeedback',

  components: {
    IcnThumbsUp,
    IcnThumbsDown,
    IcnNeutral,
    IcnDownArrow,
    IcnDownArrowRed
  },

  data () {
    return {
      langPath: __langpath,
      thumbsUp: IcnThumbsUp,
      thumbsDown: IcnThumbsDown,
      neutral: IcnNeutral,
      arrowIcn: IcnDownArrow,
      arrowIcnRed: IcnDownArrowRed
    }
  },

  computed: {
    ...currentUserHelper.mapState(['currentUser']),
    ...feedbacksHelper.mapGetters([
      'profileEvaluationsGroupedByName',
      'skillEvaluationsGroupedByName',
      'averageScore'
    ])
  },

  methods: {
    parseEvaluationName (evaluationName) {
      return this.$t(`${this.langPath}.${toCamelCase(evaluationName)}`)
    },

    scoreIcon (score) {
      switch (score) {
        case 'up':
          return this.thumbsUp
        case 'down':
          return this.thumbsDown
        case 'neutral':
          return this.neutral
      }
    },

    isCurrentUser (fromUserId) {
      return this.currentUser.id === fromUserId
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-sidebar-company-feedbacks {
  &__header,
  &__score {
    display: flex;
    justify-content: space-between;
    padding: 4*$base;
  }

  &__header {
    background-color: var(--bg-neutral-light-04);
  }

  &__title {
    @extend %subtitle1;

    color: var(--tx-red-light-01);
  }

  &__icon {
    margin-right: 3*$base;
  }

  &__edit {
    @extend %body-text2;

    &:hover {
      text-decoration: underline;
    }
  }

  &__score {
    border-bottom: 1px solid var(--b-neutral-light-04);
  }

  &__text {
    @extend %body-text2;
  }
}

.evaluation-group {
  &:not(:first-child) {
    border-top: 1px solid var(--b-neutral-light-04);
  }

  &__subgroup {
    color: var(--tx-neutral-light-01);
  }

  &__title {
    background: var(--bg-neutral-light-04);
    color: var(--tx-neutral-light-01);
    margin-bottom: 0;
    padding: 4*$base;
  }

  &__arrow {
    display: inline-block;
    vertical-align: sub;
  }
}

.evaluation-accordion {
  &__trigger {
    border: 0;
  }

  &__item {
    align-items: center;
    border-bottom: 1px solid var(--b-neutral-light-04);
    color: var(--tx-neutral-light-01);
    display: flex;
    justify-content: space-between;
    padding: 3*$base 6*$base 3*$base 10*$base;

    &:last-child {
      border: 0;
    }
  }

  &__text {
    @extend %body-text2;
  }

  &__icon {
    height: 24px;
    width: 24px;
  }
}
</style>
