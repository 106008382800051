<template>
  <section class="candidate-profile-sidebar-feedback-subject-option">
    <h3 class="candidate-profile-sidebar-feedback-subject-option__sub-title">{{ title }}</h3>

    <app-thumbs
      :name="name"
      @input="selectedEvaluate"
    />
  </section>
</template>

<script>
export default {
  name: 'CandidateProfileSidebarFeedbackSubjectOption',
  props: {
    title: {
      type: String,
      required: true
    },
    name: {
      type: Number,
      required: true
    }
  },
  methods: {
    selectedEvaluate (value) {
      const payload = { subjectId: this.name, score: value }
      this.$emit('input', payload)
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-sidebar-feedback-subject-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $base * 2;
  width: 80%;

  &__sub-title {
    @extend %body-text2;

    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }
}
</style>
