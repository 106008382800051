<template>
  <article class="candidate-profile-sidebar-feedback">
    <form>
      <section class="candidate-profile-sidebar-feedback__body">
        <figure class="candidate-profile-sidebar-feedback__photo">
          <img :src="candidate.avatar" class="candidate-profile-sidebar-feedback__photo-image">
        </figure>

        <div class="candidate-profile-sidebar-feedback__content">
          <h3 class="candidate-profile-sidebar-feedback__title">
            {{ candidate.name }}
          </h3>
          <div class="candidate-profile-sidebar-feedback__content--spacing">
            <h3>{{ $t(`${langPath}.evaluate`) }}</h3>
            <div class="candidate-profile-sidebar-feedback__content--score">
              <app-click-grid
                :options="feedbackRangeScore"
                :col-xs="10"
                :box-size="'md'"
                data-test="score-input"
                @input="handleFeedbackScore"
              />
            </div>
            <span class="candidate-profile-sidebar-feedback__content--legend">
              {{ $t(`${langPath}.scoreLegend`) }}
            </span>
          </div>
        </div>
      </section>

      <section v-if="feedbackScore">
        <ev-circle-loader
          v-if="loadingSubjects"
          class="candidate-profile-sidebar-feedback__loading-options"
          variant="tertiary"
        />

        <template v-else>
          <div class="candidate-profile-sidebar-feedback__evaluations">
            <div class="candidate-profile-sidebar-feedback__evaluations--box">
              <h3 class="candidate-profile-sidebar-feedback__title">
                {{ $t(`${langPath}.profile`) }}
              </h3>
              <div
                v-for="profile in profileSubjects"
                :key="profile.id"
              >
                <candidate-profile-sidebar-feedback-subject-option
                  :title="parseProfileName(profile)"
                  :name="parseInt(profile.id)"
                  data-test="feedback-option"
                  @input="addEvaluationToSubject"
                />
              </div>
            </div>

            <div class="candidate-profile-sidebar-feedback__evaluations--box">
              <h3 class="candidate-profile-sidebar-feedback__title">
                {{ $t(`${langPath}.skill`) }}
              </h3>
              <div
                v-for="skill in skillSubjects"
                :key="skill.id"
              >
                <candidate-profile-sidebar-feedback-subject-option
                  :title="skill.name"
                  :name="parseInt(skill.id)"
                  data-test="feedback-option"
                  @input="addEvaluationToSubject"
                />
              </div>
            </div>
          </div>
        </template>
      </section>

      <section class="candidate-profile-sidebar-feedback__description">
        <h3 class="candidate-profile-sidebar-feedback__description--caption">
          {{ $t(`${langPath}.description`, { name: candidate.name }) }}
        </h3>
        <app-text-area
          :placeholder="$t(`${langPath}.feedbackPlaceholder`)"
          data-test="description-input"
          name="feedbackDescription"
          @input="handleWithDescription"
        />
      </section>

      <section class="candidate-profile-sidebar-feedback__footer">
        <div class="candidate-profile-sidebar-feedback__footer--spacing">
          <app-checkbox
            v-model="interviewDidNotHappen"
            :label="$t(`${langPath}.interviewNotHappened`)"
            data-test="interview-did-not-happen"
            name="interviewDidNotHappen"
          />
        </div>
        <ev-button
          :disabled="submitButtonDisabled"
          :loading="loadingSubmit"
          data-test="submit-button"
          @click.prevent="handleCreateFeedback"
        >
          {{ $t(`${langPath}.submit`) }}
        </ev-button>
      </section>
    </form>
  </article>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import EvCircleLoader from '@revelotech/everestV2/EvCircleLoader'
import CandidateProfileSidebarFeedbackSubjectOption
  from './CandidateProfileSidebarFeedbackSubjectOption'
import { toCamelCase } from '@/helpers/case-style'
import { createNamespacedHelpers } from 'vuex'
import { FEEDBACKS } from '@/store/namespaces'

const feedbacksHelper = createNamespacedHelpers(FEEDBACKS)

const FEEDBACK_RANGE_SCORES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export default {
  name: 'CandidateProfileSidebarFeedback',

  components: {
    EvButton,
    EvCircleLoader,
    CandidateProfileSidebarFeedbackSubjectOption
  },

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      langPath: __langpath,
      interviewDidNotHappen: false,
      feedbackScore: undefined,
      feedbackDescription: '',
      feedbackRangeScore: FEEDBACK_RANGE_SCORES.map((n) => ({
        value: n,
        label: String(n)
      })),
      subjectsEvaluation: [],
      loadingSubjects: true,
      loadingSubmit: false
    }
  },

  computed: {
    ...feedbacksHelper.mapState(['subjects']),
    submitButtonDisabled () {
      return !(this.interviewDidNotHappen || this.feedbackScore)
    },
    profileSubjects () {
      return this.subjects.filter((subject) =>
        subject.model === 'Feedbacks::ProfileSubject')
    },
    skillSubjects () {
      return this.subjects.filter((subject) =>
        subject.model === 'Feedbacks::SkillSubject')
    }
  },

  async created () {
    if (!this.candidate?.invite?.id) return

    try {
      await this.getFeedbackSubjects({
        inviteId: this.candidate.invite.id,
        interviewId: this.candidate.invite.interviewIdAvailableToFeedback
      })
    } finally {
      this.loadingSubjects = false
    }
  },

  methods: {
    ...feedbacksHelper.mapActions(['createFeedback', 'getFeedbackSubjects']),
    parseProfileName (profile) {
      return this.$t(`${this.langPath}.${toCamelCase(profile.name)}`)
    },
    handleFeedbackScore (event) {
      this.feedbackScore = event[0]
    },
    handleWithDescription (event) {
      this.feedbackDescription = event
    },
    addEvaluationToSubject (event) {
      this.subjectsEvaluation = this.subjectsEvaluation.filter((subject) => {
        return subject.subjectId !== event.subjectId
      })
      this.subjectsEvaluation.push(event)
    },
    async handleCreateFeedback (_event) {
      this.loadingSubmit = true

      try {
        const responseApi = await this.createFeedback({
          inviteId: this.candidate.invite.id,
          interviewId: this.candidate.invite.interviewIdAvailableToFeedback,
          feedback: {
            score: this.feedbackScore,
            text: this.feedbackDescription,
            evaluations: this.subjectsEvaluation
          }
        })

        this.$emit('submit', responseApi)
      } finally {
        this.loadingSubmit = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-profile-sidebar-feedback {
  @include margin(right, 3);

  &__body {
    @extend %body-text2;

    @include margin(top, 3);

    display: flex;
  }

  &__content {
    flex-grow: 1;

    &--spacing {
      @include margin(top, 1);
    }

    &--score {
      @include margin(top, 1);

      height: $base * 8;
    }

    &--legend {
      @extend %caption;

      @include margin(top, 1);

      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__description {
    @include margin(top, 3);

    &--caption {
      @extend %body-text2;
    }
  }

  &__evaluations {
    @extend %body-text2;

    @include margin(top, 3);

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--box {
      flex-basis: 50%;
    }
  }

  &__loading-options {
    margin-bottom: $base * 8;
    margin-top: $base * 4;
  }

  &__footer {
    @extend %body-text2;

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &--spacing {
      @include margin(right, 3);
    }
  }

  &__title {
    @extend %bold;
  }

  &__photo {
    align-self: center;
    flex-shrink: 0;
    height: 19*$base;
    width: 19*$base;

    @include breakpoint(md) {
      align-self: flex-start;
    }

    @include margin(right, 3);

    &-image {
      height: 100%;
      max-width: 100%;
    }
  }
}
</style>
